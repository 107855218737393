// PROD
// module.exports = {
//   APP_ID: "ladecora",
//   APP_NAME: "Ladecora",
//   API: "https://api-prod.ladecora.com.br",
//   BUCKET_URL: "https://ladecora.s3.us-east-2.amazonaws.com",
//   PRODUCTION: false,
//   PAGARME_TOKEN: "ek_live_uqPydxBlIz8lVvG3tIGhgJ43vXW1bh",
// };

// DEV
module.exports = {
  APP_NAME: "Ladecora",
  APP_ID: "ladecora",
  DEFAULT_COMPANY: "ladecora",
  API: "https://api-dev.ladecora.com.br",
  // API: "http://localhost:8081",
  BUCKET_URL: "https://s3.amazonaws.com/dev-ladecora.platform",
  PRODUCTION: false,
  PAGARME_TOKEN: "ek_test_H3QV3IyqF1Lr8IYJ5LQA2uY5AX3psK",
  GOOGLE_APP_ID:
    "558784496561-4pcdg7j04la3a0ut3ave9ami6ldn261f.apps.googleusercontent.com",
  FACEBOOK_APP_ID: "149831527245732",
  TAG_MANAGER: "GTM-",
  GA4: "331367635",
};
